let DragPos = { x: null, y: null, w: 2, h: 2, i: null }

export default {
	methods: {
		onDragLeaveContainer() {
			this.emitItemDropEnd("drag")
			this.removeItem("drop")
		},
		emitItemDropEnd(index) {
			this.$refs.gridlayout.dragEvent("dragend", index, DragPos.x, DragPos.y, DragPos.w, DragPos.h)
		},

		createDragPlaceholder() {
			this.layout.push({
				x: (this.layout.length * 2) % (this.colNum || 12),
				y: this.layout.length + (this.colNum || 12), // puts it at the bottom
				w: 2,
				h: 2,
				i: "drop",
				hide: true
			})
		},

		drag: function (e, dashboardItem) {
			let { inside: mouseInGrid, rect: parentRect } = this.isMouseInside(this.$refs.content)

			if (mouseInGrid && !this.layout.find((item) => item.i === "drop")) {
				this.createDragPlaceholder()
				this.layout
			}

			let index = this.layout.findIndex((item) => item.i === "drop")

			if (index !== -1) {
				if (mouseInGrid === true) {
					const top = this.mouseXY.y - parentRect.top
					const left = this.mouseXY.x - parentRect.left

					let el = this.$refs.gridlayout.$children[index]

					el.dragging = { top, left }
					let posInGrid = el.calcXY(top, left)

					this.$refs.gridlayout.dragEvent("dragstart", "drop", posInGrid.x, posInGrid.y, 2, 2)

					Object.assign(DragPos, {
						i: index,
						x: this.layout[index].x,
						y: this.layout[index].y
					})
				} else {
					this.onDragLeaveContainer()
				}
			}
		},

		dragend: function (type, dashboardItem) {
			let { inside: mouseInGrid } = this.isMouseInside(this.$refs.content)

			if (mouseInGrid === true) {
				this.onDragLeaveContainer()
				this.layout.push({
					x: DragPos.x,
					y: DragPos.y,
					w: DragPos.w,
					h: DragPos.h,
					i: DragPos.i,
					type: type,
					_id: new Date().getMilliseconds(),
					options: dashboardItem.defaultOptions()
				})

				this.$emit("input", this.layout)
				this.emitItemDropEnd("drop")
			}
		}
	}
}
