<template>
    <div>
        <page-renderer :name="item.options.name"></page-renderer>
        <span v-if="isRemovable" class="remove" @click="removeItem(item.i)">x</span>
    </div>
</template>
<script>
import PageRenderer from "@Platon/components/pages/PageRenderer.vue"
export default {
    name: "PageItem",

    props: {
        item: {},
        isRemovable: {
            type: Boolean,
            default: true
        }
    },
    components: { PageRenderer },

    methods: {
        removeItem(i) {
            this.$emit("removeItem", i)
        }
    }
}
</script>
