<template>
    <div :class="{ 'mt-3': level > 1 }">
        <div style="font-weight: bold">{{ item.label || item.name }}</div>
        <div :class="{ 'pl-3': level > 1 }">
            <component
                :level="level + 1"
                :is="`builder-` + element.type"
                :key="element.name"
                :item="element"
                v-model="local[element.name]"
                v-for="element in formActiveElements"
            />
        </div>
    </div>
</template>
<script>
import BuilderSelect from "./BuilderSelect"
import BuilderText from "./BuilderText"
import BuilderCheckBox from "./BuilderCheckBox"

export default {
    name: "BuilderGroup",

    props: {
        item: {},
        value: {},
        level: {
            type: Number,
            default: 1
        }
    },

    components: {
        BuilderText,
        BuilderSelect,
        BuilderCheckBox
    },

    created() {
        const newOptions = this.item.children.reduce(function (newOptions, item) {
            if (item.type === "group") {
                newOptions[item.name] = {}
            }

            return newOptions
        }, {})

        if (Object.keys(newOptions).length > 0) {
            Object.assign(this.local, newOptions)
        }
    },

    methods: {
        getOptionValue(key) {
            if (this.local[key] === undefined) {
                this.local[key] = {}
            }

            return this.local[key]
        }
    },

    computed: {
        formActiveElements() {
            return this.item.children.filter((el) => {
                if (typeof el.canRender === "function") {
                    return el.canRender(this.local)
                }

                return true
            })
        },

        local: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit("input", value)
            }
        }
    }
}
</script>
