<template>
    <div class="generator">
        <h4>{{ $l("platon.generate_layout_area", "Маълумотларни созлаш соҳаси") }}</h4>
        <button class="btn btn-success my-3" @click="copyLayoutData">
            {{ $l("platon.platon.copy_as_json", "Json шаклида нусха олиш") }}
        </button>
        <div>
            <form-builder v-if="isEmpty(selected)" v-model="gridItemOptions" :form="form" :selected="selected" />
            <p v-else>{{ $l("platon.select", "Танланг") }}</p>
        </div>
    </div>
</template>
<script>
import FormBuilder from "@Platon/components/builder/form/FormBuilder.vue"
import TextControl from "@Platon/components/form/controls/TextControl.vue"
import GetDashboardItemOptions from "./items/ChartItemOptions"

export default {
    props: {
        data: {
            required: true,
            type: Array
        },
        selected: {
            required: false,
            type: Object
        }
    },

    inject: ["dashboard"],

    components: {
        TextControl,
        FormBuilder
    },

    computed: {
        form() {
            const type = this.selected.type

            return GetDashboardItemOptions(type)
        },

        gridItemOptions() {
            return this.selected?.options || {}
        }
    },

    methods: {
        copyLayoutData() {
            navigator.clipboard.writeText(JSON.stringify(this.data, null, 2))
        },
        isEmpty(obj) {
            return Object.keys(obj).length !== 0
        }
    },

    watch: {
        gridItemOptions: {
            handler(value) {
                this.dashboard.changeChartOptions({
                    options: { ...value },
                    index: this.selected.i
                })
            },
            deep: true
        }
    }
}
</script>
<style scoped>
.generator {
    padding: 10px;
}

td {
    min-width: 70px;
}
</style>
