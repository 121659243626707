import { $language } from "@Platon/core/translations"
const chartTypes = ["tree", "pyramid", "pie", "column", "line", "bar", "areaspline", "variablepie"]

const ArrayToStringTransformer = {
	/**
	 * @param {Number[]} value
	 */
	get(value) {
		if (value) {
			return value.join(",")
		}

		return value
	},

	/**
	 * @param {string} value
	 * @returns {Number[]}
	 */
	set(value) {
		return value.split(",")
	}
}

const JSONToStringTransformer = {
	/**
	 * @param {Object[]|Array} value
	 */
	get(value) {
		return JSON.stringify(value)
	},

	/**
	 * @param {string} value
	 * @returns {Object[]|Array}
	 */
	set(value) {
		return JSON.parse(value)
	}
}

export const ChartOptionsForm = [
	{
		name: "type",
		type: "select",
		label: $language("platon.type", "Тури"),
		options: [
			{
				disabled: true,
				value: null,
				label: $language("platon.select", "Танланг")
			},
			{ disabled: false, value: "pie", label: "Pie chart" },
			{ disabled: false, value: "tree", label: "Tree chart" },
			{ disabled: false, value: "pyramid", label: "Pyramid chart" },
			{ disabled: false, value: "column", label: "Column chart" },
			{ disabled: false, value: "line", label: "Line chart" },
			{ disabled: false, value: "bar", label: "Bar chart" },
			{ disabled: false, value: "areaspline", label: "Areaspline chart" },
			{ disabled: false, value: "variablepie", label: "Variable piechart" }
		]
	},
	{
		type: "text",
		name: "title",
		label: $language("platon.title", "Сарлавҳа"),
		canRender(options) {
			return chartTypes.includes(options.type)
		}
	},
	{
		type: "text",
		name: "subtitle",
		label: $language("platon.subtitle", "Субтитр"),
		canRender(options) {
			return chartTypes.includes(options.type)
		}
	},
	{
		type: "text",
		name: "endPoint",
		label: $language("platon.endPoint", "ЭндПоинт"),
		canRender(options) {
			return chartTypes.includes(options.type)
		}
	},
	{
		type: "text",
		name: "colors",
		label: $language("platon.colors", "Ранглар"),
		canRender(options) {
			return chartTypes.includes(options.type)
		},

		transformer: ArrayToStringTransformer
	},
	{
		type: "text",
		name: "categories",
		label: "Категориялар",
		canRender(options) {
			return ["bar", "areaspline", "column"].includes(options.type)
		},

		transformer: ArrayToStringTransformer
	},
	{
		type: "check-box",
		name: "stacking",
		label: $language("platon.second-type", "второй тип"),
		canRender(options) {
			return ["bar", "column"].includes(options.type)
		},

		transformer: ArrayToStringTransformer
	},
	{
		type: "text",
		name: "startPoint",
		label: "Бошланиш нуктаси",
		canRender(options) {
			return ["line"].includes(options.type)
		},

		transformer: ArrayToStringTransformer
	}

	// {
	// 	type: "text",
	// 	name: "data",
	// 	transformer: JSONToStringTransformer,
	// 	canRender(options) {
	// 		return chartTypes.includes(options.type);
	// 	}
	// }
]

export const PageOptionsForm = [
	{
		type: "text",
		name: "name"
	}
]

const options = {
	chart: ChartOptionsForm,
	page: PageOptionsForm
}

export default function GetDashboardItemOptions(type) {
	return options[type]
}
