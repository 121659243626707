<template>
    <div style="position: relative">
        <platon-chart :options="chart"></platon-chart>
        <span v-if="isRemovable" class="remove" @click="removeItem(item.i)">x</span>
    </div>
</template>
<script>
import merge from "lodash/merge"

const xPoints = {
    pie: { type: "value" },
    pyramid: { title: "val" }
}

export default {
    name: "ChartItem",

    props: {
        item: {},
        isRemovable: {
            type: Boolean,
            default: true
        }
    },

    mounted() {
        this.$parent.$on("resized", () => {
            this.updateId++
        })
    },

    data() {
        return {
            chartData: undefined,
            updateId: 0,
            // !
            pyramid: {
                type: "pyramid",
                xPoints: { title: "val" },
                data: [
                    { title: "Manaviy hordiq", val: 10 },
                    { title: "Iqtisodiy erkinlik", val: 30 },
                    { title: "Oziq-ovqat", val: 70 },
                    { title: "Xavfsizlik", val: 100 }
                ],
                showSeriesLabels: true,
                showDataLabels: true
            },
            // !
            tree: {
                type: "tree",

                colors: ["#f00", "#0f0", "#00f", "cyan", "cyan"],
                pointFields: {
                    id: "id",
                    title: "name1",
                    value: "val",
                    parent: "int01"
                },
                data: [
                    { id: 3, int01: null, name1: "Андижон вилояти", val: 5 },
                    { id: 6, int01: null, name1: "Бухоро вилояти", val: 3 },
                    { id: 8, int01: null, name1: "Жиззах вилояти", val: 19 },
                    { id: 301, int01: 3, name1: "Андижон шаҳри", val: 7 },
                    { id: 303, int01: 3, name1: "Хонобод шаҳри", val: 6 },
                    { id: 318, int01: 3, name1: "Пахтаобод тумани", val: 3 },
                    { id: 319, int01: 3, name1: "Хўжаобод тумани", val: 4 },
                    { id: 614, int01: 6, name1: "Қоровулбозор туман", val: 1 },
                    { id: 601, int01: 6, name1: "Бухоро шаҳри", val: 9 },
                    { id: 801, int01: 8, name1: "Арнасой тумани", val: 4 },
                    { id: 812, int01: 8, name1: "Жиззах шаҳри", val: 8 },
                    { id: 813, int01: 8, name1: "Янгиобод тумани", val: 6 }
                ],
                showSeriesLabels: true,
                showDataLabels: true
            },
            pie: {
                type: "pie",
                suffix: "",
                xPoints: { name: "y" },
                colors: ["#3766C4", "#00b0f0", "#014280", "#757778"],
                title: "Browser market shares in January, 2018",
                chartConfig: {
                    chart: {
                        height: 300
                    },
                    plotOptions: {
                        pie: {
                            depth: 60,
                            startAngle: -90,
                            endAngle: 90,
                            distance: -50,
                            center: ["50%", "75%"],
                            innerSize: "50%",
                            size: "140%"
                        }
                    }
                }
            },
            column: {
                type: "column",
                title: "Жойлашуви бўйича",
                xLabel: "status",
                xPoints: {
                    building: "бинода",
                    ground: "ерда",
                    construction: "иншоотда",
                    all: "жами"
                },
                chartConfig: {
                    chart: {
                        height: "300px"
                    },
                    plotOptions: {
                        column: {
                            stacking: "normal",
                            dataLabels: {
                                enabled: true
                            }
                        }
                    },
                    series: [
                        {
                            name: "2020",
                            data: [2, 2, 3, 2]
                        },
                        {
                            name: "2021",
                            data: [3, 4, 4, 2]
                        }
                    ],
                    xAxis: { categories: ["бинода", "ерда", "иншоотда", "жами"] }
                }
            },
            line: {
                type: "line",
                title: "Line чартининг тайтели",
                xLabel: "status",
                chartConfig: {
                    legend: {
                        layout: "vertical",
                        align: "right",
                        verticalAlign: "middle"
                    },
                    plotOptions: {
                        series: {
                            pointStart: 2000
                        }
                    },
                    series: []
                }
            },
            areaspline: {
                type: "areaspline",
                title: "Areaspline чартининг тайтели",
                xLabel: "status",
                xPoints: {},
                chartConfig: {
                    chart: {
                        height: "450px"
                    },
                    plotOptions: {},
                    series: [],
                    xAxis: {
                        categories: []
                    },
                    yAxis: {
                        min: 0,
                        labels: {
                            overflow: "justify"
                        }
                    }
                }
            },
            bar: {
                type: "bar",
                title: "Bar чартининг тайтели",
                xLabel: "status",
                xPoints: {},
                chartConfig: {
                    chart: {
                        height: "450px"
                    },
                    plotOptions: {
                        bar: {
                            dataLabels: {
                                enabled: true
                            }
                        },
                        series: {
                            stacking: "normal"
                        }
                    },
                    series: [],
                    xAxis: {
                        categories: []
                    },
                    yAxis: {}
                }
            },
            variablepie: {
                type: "variablepie",
                title: "Variablespline чартининг тайтели",
                suffix: "",
                xPoints: { name: "y" },
                colors: [],
                showSeriesLabels: true,
                chartConfig: {
                    chart: {
                        height: 450
                    },
                    plotOptions: {
                        tooltip: {
                            headerFormat: "",
                            pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>\''
                        }
                    },
                    series: [
                        {
                            minPointSize: 10,
                            innerSize: "20%",
                            zMin: 0,
                            name: "countries",
                            data: []
                        }
                    ]
                }
            }
        }
    },

    computed: {
        chart() {
            this.updateId

            const { type = "tree" } = this.item.options
            const defaultOptons = this[type]

            const transformedOptions = this.transformOptions(
                this.item,
                merge({}, defaultOptons, this.item.options, { data: this.chartData })
            )

            return this.transformedXPoints(transformedOptions)
        }
    },

    methods: {
        removeItem(i) {
            this.$emit("removeItem", i)
        },

        async getChartData(url) {
            try {
                let response = await this.$api(url)
                this.chartData = response.data.data
            } catch (err) {
                console.log(err)
            }
        },

        transformOptions(chart, options) {
            let chartConfigs = options.chartConfig
            if (chart.options.type === "pie") {
                if (Array.isArray(options.colors) && Array.isArray(options.data)) {
                    options.colors.forEach((color, index) => {
                        if (options.data[index]) options.data[index]["color"] = color
                    })
                }
            } else if (chart.options.type === "column") {
                chartConfigs.series = this.chartData
                chartConfigs.xAxis.categories = chart.options.categories
                chartConfigs.plotOptions.column.stacking = chart.options.stacking ? "normal" : ""
            } else if (chart.options.type === "line") {
                chartConfigs.series = this.chartData

                if (chart.options.startPoint) {
                    chartConfigs.plotOptions.series.pointStart = parseInt(chart.options.startPoint[0])
                }

                let outColor = chartConfigs.series
                this.setColorFunction(outColor, chart)
            } else if (chart.options.type === "bar") {
                chartConfigs.series = this.chartData
                chartConfigs.colors = chart.options.colors
                chartConfigs.xAxis.categories = chart.options.categories
                chartConfigs.plotOptions.series.stacking = chart.options.stacking ? "normal" : ""
            } else if (chart.options.type === "areaspline") {
                chartConfigs.series = this.chartData
                chartConfigs.colors = chart.options.colors
                chartConfigs.xAxis.categories = chart.options.categories
            } else if (chart.options.type === "variablepie") {
                chartConfigs.series[0].data = this.chartData

                let outColor = chartConfigs.series[0]["data"]
                this.setColorFunction(outColor, chart)
            }

            return options
        },

        setColorFunction(outColor, chart) {
            let incomingColors = chart.options.colors ? chart.options.colors : []
            if (this.chartData && incomingColors.length <= this.chartData.length) {
                for (let i = 0; i < incomingColors.length; i++) {
                    outColor[i]["color"] = incomingColors[i]
                }
            }
        },

        transformedXPoints(points) {
            points.xPoints = xPoints[points.type]
            return points
        }
    },

    watch: {
        "item.options.endPoint": {
            immediate: true,
            handler(url) {
                const { type } = this.item.options
                this.getChartData(url, type)
            }
        },
        "item.options.type": {
            immediate: true,
            handler() {
                this.chartData = ""
            }
        }
    }
}
</script>
