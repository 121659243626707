<template>
    <div>
        <div class="d-flex px-2">
            <dragger
                v-for="item in dashboardItems"
                :key="item.type"
                @drag="drag($event, item)"
                @dragend="dragend($event, item)"
                :item="item"
                :title="item.title"
            />
        </div>
        <div ref="content" class="grid-content pb-3">
            <grid-layout
                ref="gridlayout"
                :layout.sync="layout"
                :col-num="12"
                :row-height="30"
                :is-draggable="true"
                :is-resizable="true"
                :is-mirrored="false"
                :vertical-compact="true"
                :margin="[5, 5]"
                :use-css-transforms="false"
            >
                <grid-item
                    v-show="!item.hide"
                    v-for="item in layout"
                    class="overflow-hidden"
                    ref="activeGrid"
                    @click.native="handleSelect(item)"
                    :x="item.x"
                    :y="item.y"
                    :w="item.w"
                    :h="item.h"
                    :i="item.i"
                    :key="item._id"
                >
                    <component v-if="item.type" :is="`${item.type}-item`" :item="item" @removeItem="removeItem" />
                </grid-item>
            </grid-layout>
        </div>
    </div>
</template>

<script>
import { GridItem, GridLayout } from "vue-grid-layout"
import DragAndDropMixin from "@Platon/components/builder/DragAndDropMixin"
import DashboardD2DItemMixin from "@Platon/components/builder/DashboardD2DItemMixin"
import Dragger from "@Platon/components/builder/Dragger"
import PlatonChart from "@Platon/components/chart/PlatonChart"
import ChartItem from "./items/ChartItem.vue"
import PageItem from "./items/PageItem.vue"

export default {
    name: "ResizablePage",

    mixins: [DashboardD2DItemMixin, DragAndDropMixin],

    components: {
        GridLayout,
        GridItem,
        Dragger,
        PlatonChart,
        ChartItem,
        PageItem
    },

    props: {
        value: {
            required: true,
            type: Array
        }
    },

    inject: ["dashboard"],

    data() {
        return {
            layout: this.value,
            dashboardItems: [
                {
                    type: "chart",
                    title: "Chart",
                    defaultOptions: () => ({ type: "tree" })
                },
                {
                    type: "page",
                    title: this.$l("platon.page", "Саҳифа"),
                    defaultOptions: () => ({ name: "test" })
                }
            ]
        }
    },

    methods: {
        removeItem: function (val) {
            this.layout.splice(
                this.layout.findIndex((item) => item.i == val),
                1
            )

            this.layout.forEach((el, ind) => (el.i = ind))
        },

        handleSelect(element) {
            this.$refs.activeGrid.forEach((item) => {
                item.i == element.i ? item.$el.classList.add("active") : item.$el.classList.remove("active")
            })
            this.dashboard.selectItem(element)
        }
    }
}
</script>

<style lang="scss">
.vue-grid-item {
    border: 1px solid #e6e6e6;
    background-color: white;
    border-radius: 0.2rem;
}
.vue-grid-item.active {
    border: 1px solid var(--pl-top-bar);
}
.grid-content {
    padding-top: 5px;
    border-top: 1px solid #e6e6e6;
}
.droppable-element {
    width: 180px;
    text-align: center;
    border: 1px dotted black;
    margin: 10px 0;
    padding: 10px;
}
.remove {
    position: absolute;
    right: 2px;
    top: 0;
    cursor: pointer;
    padding: 10px;
}

.vue-grid-item.vue-grid-placeholder {
    background: var(--pl-top-bar) !important;
}
</style>
